import { Component, OnInit, Output, ViewEncapsulation, EventEmitter, ViewChild, ElementRef } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Subject, takeUntil } from "rxjs";
import { ActivatedRoute, Router } from "@angular/router";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { emailValidator } from "../core/directives/email.directive";
import { phoneNumberValidator } from "../core/directives/phone.directive";
import { ThemeService } from "../core/services/theme.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { RoutePositionService } from "../core/services/route.position.service";
import { JourneyOfflineService } from "../core/services/journey.offline.service";

@Component({
	selector: "app-data-collection",
	templateUrl: "./data-collection.component.html",
	styleUrl: "data-collection.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class DataCollectionComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	form: FormGroup;
	dataColData: any;
	footerBtnText: string;
	footerText: string;
	campaignHeader: any;
	dataColHeaderOpt: boolean;
	dataColFooterOpt: boolean;
	campaignLang: string;

	// LAS Log
	lasData: any;
	stepName: string;
	dataCollectionLogVerfication: any;

	// CTA Theme
	colorCTA: string = "#fff";
	bgColorCTA: string = "teal";
	borderCTA: string = "none";

	backgroundImageUrl: string;

	// Data Collection
	emailInput: string;
	phoneInput: any;

	// Input logic
	isEmailRequired: boolean;
	isPhoneRequired: boolean;

	// Inputs placeholder
	emailPlaceholder: string;
	phonePlaceholder: string;

	isBtnDisabled: boolean = true;

	// Final Step
	isFinalStep: boolean = false;

	// Routing config
	isAnyNextRoute: any;

	// Campaign CTA Text
	ctaNextText: string;
	ctaRetryText: string;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private routingOrderService: RoutingOrderService,
		private fb: FormBuilder,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private routePositionService: RoutePositionService,
		private journeyOfflineService: JourneyOfflineService
	) {}
	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		this.routeGuard.markRouteAsVisited("data-collection");

		// Check if this route is the final step
		this.isFinalStep = this.routePositionService.getRouteOrderPosition("data-collection");

		// Add step to the journey offline
		this.journeyOfflineService.addStep("data-collection", this.isFinalStep);

		this.initializeLogVerfication();

		// Setup both inputs
		this.form = this.fb.group({
			email: [""],
			phone: [""],
		});

		this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
			if (data != null) {
				this.campaignLang = data.campaign?.lang;
				this.campaignHeader = data.campaign?.theme.header;
				this.footerText = data.campaign?.theme.footer;

				this.ctaNextText = data.campaign.theme.cta?.ctaNextText;
				this.ctaRetryText = data.campaign.theme.cta?.ctaRetryText;

				this.dataColData = data.campaign.steps.filter((e) => e.__component === "screen.data-collection")[0];

				this.dataColHeaderOpt = this.dataColData?.isHeaderEnabled;
				this.dataColFooterOpt = this.dataColData?.isFooterEnabled;

				this.isPhoneRequired = this.dataColData?.isPhoneRequired;
				this.isEmailRequired = this.dataColData?.isEmailRequired;

				this.emailPlaceholder = this.dataColData?.email ? this.dataColData?.email : "";
				this.phonePlaceholder = this.dataColData?.phone ? this.dataColData?.phone : "";

				// if (this.campaignLang === "fr") {
				// 	this.emailPlaceholder = "Entrer ton email";
				// 	this.phonePlaceholder = "Entrer ton téléphone";
				// } else if (this.campaignLang === "ar") {
				// 	this.emailPlaceholder = "أدخل بريدك الإلكتروني";
				// 	this.phonePlaceholder = "أدخل رقم هاتفك";
				// }

				// Check if any input is required to setup
				if (this.isPhoneRequired && this.isEmailRequired) {
					this.form = this.fb.group({
						email: ["", [Validators.required, emailValidator()]],
						phone: ["", [Validators.required, phoneNumberValidator()]],
					});
				} else if (this.isPhoneRequired) {
					this.form = this.fb.group({
						phone: ["", [Validators.required, phoneNumberValidator()]],
					});
				} else if (this.isEmailRequired) {
					this.form = this.fb.group({
						email: ["", [Validators.required, emailValidator()]],
					});
				}

				// Log step
				const stepLebelFormated = this.removeSpacesBetweenWords(this.dataColData.stepLabel);
				if (!this.dataCollectionLogVerfication[stepLebelFormated]) {
					this.stepName = this.dataColData.stepLabel;
					this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
						if (data != null) {
							this.lasData = data;
							this.logStep(this.lasData.journey, this.stepName, { ...(this.isFinalStep && { isFinalStep: true }) });
						}
					});

					this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
				}

				// Set CTA text
				this.isAnyNextRoute = this.routingOrderService.getNextRoute("data-collection");
				this.footerBtnText = this.dataColData?.ctaText;
				// if (!this.isAnyNextRoute) {
				// 	this.footerBtnText = this.ctaRetryText;
				// } else {
				// 	if (this.dataColData?.ctaText) {
				// 		this.footerBtnText = this.dataColData?.ctaText;
				// 	} else {
				// 		this.footerBtnText = this.ctaNextText;
				// 	}
				// }

				// Set theme --------------------
				const theme = data.campaign.theme;
				const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(theme, this.dataColData);
				this.ngStyle = ngStyle;
				this.backgroundImageUrl = backgroundImageUrl;
			}
		});
	}

	ngAfterViewInit(): void {}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		if (this.form.valid) {
			// Get form values
			const formValue = this.form?.value;

			// Process the form data
			console.log(formValue);

			// If is final step remove the log verfication
			if (this.isFinalStep) {
				localStorage.removeItem("logVerfication");
				localStorage.removeItem("visitedRoutes");
			}

			// Next route
			const currentPath = this.activatedRoute.snapshot?.routeConfig.path;
			this.routingOrderService.navigateToNext(currentPath);
		} else {
			console.log("Form is invalid", this.form.get("phone").errors);
			console.log("Form is invalid", this.form.get("email").errors);
		}
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	isInputsValid() {
		// Check if any input is required
		if (this.isPhoneRequired && this.isEmailRequired) {
			const phoneValid = /^0[1-9]\d{8}$/.test(this.phoneInput);
			const emailValid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.emailInput);

			return phoneValid && emailValid;
		} else if (this.isPhoneRequired) {
			const valid = /^0[1-9]\d{8}$/.test(this.phoneInput);
			return valid;
		} else if (this.isEmailRequired) {
			const valid = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(this.emailInput);
			return valid;
		}

		return false;
	}

	logStep(journeyId: number, stepLabel: string, additionalData = {}) {
		this.lasService
			.logStep(journeyId, stepLabel, additionalData)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey data collection created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.dataCollectionLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.dataCollectionLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.dataCollectionLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}
}

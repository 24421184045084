import { MenuItem } from "./menu.model";

export const MENU: MenuItem[] = [
	// DASHBOARD ==========================================
	/* {
		label: "MENUITEMS.DASHBOARD.LABEL",
		subLabel: "MENUITEMS.DASHBOARD.SUB_LABEL",
		isTitle: true,
	},
	// {
	// 	label: "MENUITEMS.DASHBOARD.LIST.OVERVIEW",
	// 	icon: "las la-chart-pie",
	// 	link: "/admin/dashboard/global",
	// },
	{
		label: "MENUITEMS.DASHBOARD.LIST.BRANDS",
		icon: "las la-chart-bar",
		link: "/admin/dashboard/brands",
	},
	{
		label: "MENUITEMS.DASHBOARD.LIST.CAMPAIGNS",
		icon: "las la-chart-line",
		link: "/admin/dashboard/overview",
	}, */

	// CAMPAIGNS ==========================================
	{
		label: "MENUITEMS.CAMPAIGNS.LABEL",
		subLabel: "MENUITEMS.CAMPAIGNS.SUB_LABEL",
		isTitle: true,
	},
	{
		label: "MENUITEMS.CAMPAIGNS.BRANDS",
		icon: "las la-tags",
		link: "/admin/campaigns/brands",
	},
	{
		label: "MENUITEMS.CAMPAIGNS.CAMPAIGNS",
		icon: "las la-bullhorn",
		link: "/admin/campaigns/list",
	},
	{
		label: "MENUITEMS.CAMPAIGNS.LASES",
		icon: "las la-user-tag",
		link: "/admin/campaigns/lases",
	},
	{
		label: "MENUITEMS.CAMPAIGNS.JOURNEYS",
		icon: "las la-history",
		link: "/admin/campaigns/journeys",
	},
	{
		label: "MENUITEMS.CAMPAIGNS.WINNINGS",
		icon: "las la-gifts",
		link: "/admin/campaigns/winnings",
	},

	// PRODUCTS ===========================================
	{
		label: "MENUITEMS.PRODUCTS.LABEL",
		subLabel: "MENUITEMS.PRODUCTS.SUB_LABEL",
		isTitle: true,
	},
	{
		label: "MENUITEMS.PRODUCTS.PHYSICALS",
		icon: "las la-box",
		link: "/admin/products/physicals/list",
	},

	{
		label: "MENUITEMS.PRODUCTS.VOUCHERS",
		icon: "las la-clipboard-list",
		link: "/admin/products/vouchers/type",
	},
	{
		label: "MENUITEMS.PRODUCTS.VOUCHERS_SOURCE",
		icon: "las la-barcode",
		link: "/admin/products/vouchers/code",
	},

	// GAMES STORE ========================================
	{
		label: "MENUITEMS.STORE.LABEL",
		subLabel: "MENUITEMS.STORE.SUB_LABEL",
		isTitle: true,
	},
	{
		label: "MENUITEMS.STORE.GAMES",
		icon: "las la-store",
		link: "/admin/products/games",
	},

	// SETTINGS ===========================================
	{
		label: "MENUITEMS.SETTINGS.LABEL",
		subLabel: "MENUITEMS.SETTINGS.SUB_LABEL",
		isTitle: true,
	},
	{
		label: "MENUITEMS.SETTINGS.USERS",
		icon: "las la-user-cog",
		link: "/admin/settings/users",
	},
	{
		label: "MENUITEMS.SETTINGS.GENERAL",
		icon: "las la-cog",
		link: "/admin/settings/lists",
	},
];

<main [ngStyle]="ngStyle">
	<!-- Header section -->
	@if (resultHeaderOpt) {
		<header class="header-section">
			<h2 class="header-title">
				<img class="header-img" [src]="backendURL + campaignHeader?.logo.url" alt="" />
				<div class="header-text" [innerHTML]="campaignHeader?.text"></div>
			</h2>
		</header>
	}

	<!-- Content section -->
	@if (wheelData) {
		<section class="content-container" id="content-container">
			<div class="main-content">
				@if (userWheelStatus === "lose") {
					@if (lang == "ar") {
						<div class="content-inner" [innerHTML]="this.lostMessage | safeHtml"></div>
					} @else {
						<div class="content-inner" [innerHTML]="this.lostMessage | safeHtml"></div>
					}
				} @else if (userWheelStatus === "win") {
					<div class="code-content">
						<h3>{{ winMessage }}</h3>
						@if (userWheelProductType === "physical") {
							<div class="code-card">
								<img [src]="backendURL + userWheelProductImg" alt="" style="width: 140px" />
							</div>
						} @else {
							<div class="code-card">
								<img [src]="backendURL + userWheelProductImg" alt="" style="width: 140px" />
								<p>{{ userWheelProductName }}</p>
							</div>
						}

						@if (!isInputHasValue && userWheelProductType === "voucher") {
							<div class="code-input">
								<p>{{ lang == "ar" ? "اختار شركتك ديال الاتصال باش تحصل على كود التعبئة" : "Sélectionne ton opérateur pour avoir ton code de recharge" }}</p>
								<div class="input-group">
									<label class="input-group-text bg-white border-0" for="inputGroupSelect01">
										<img src="/assets/las/images/simImg.png" alt="" />
									</label>

									<select class="form-select border-0" id="inputGroupSelect01" [(ngModel)]="selectedRegion" (change)="onRegionChange($event)">
										<option value="" disabled selected hidden>
											@if (lang === "fr") {
												Sélectionner ton opérateur
											} @else if (lang === "ar") {
												حدد المشغل الخاص بك
											}
										</option>
										@for (item of inputSelectValues; track $index) {
											<option [value]="item.id">{{ item.name }}</option>
										}
									</select>
								</div>
							</div>
						} @else {
							<div class="code-value">
								<!-- Remove the product code if the user is hostess and the product type is physical -->
								@if (!(userWheelProductType === "physical" && checkUserHostess())) {
									<div class="code-value-card" [cdkCopyToClipboard]="userWheelProductCode" (cdkCopyToClipboardCopied)="handleClipboardEvent()">
										@if (isCopied) {
											<i class="bi bi-clipboard-check"></i>
										} @else {
											<i class="bi bi-copy"></i>
										}
										<span>{{ userWheelProductCode }}</span>
									</div>
								}

								<p>{{ resultMessage }}</p>
							</div>
						}
					</div>
				}
			</div>
		</section>
	}

	<!-- Footer section -->
	<footer class="footer-section">
		<div class="footer-btn-next">
			<button class="btn btn-danger footer-btn" (click)="onFooterBtnClick()">{{ footerBtnText }}</button>
		</div>

		@if (resultFooterOpt) {
			<div class="footer-text-section">
				@if (footerText) {
					<div class="footer-text" [innerHTML]="footerText | safeHtml"></div>
				}
			</div>
		}
	</footer>
</main>

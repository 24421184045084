<!-- Main -->
<main class="gradient-bg">
	<!-- Content section -->
	<section class="content-container" id="content-container">
		@if (isHostessLogin) {
			<button class="logout-button" (click)="handleLogout()">
				<svg
					xmlns="http://www.w3.org/2000/svg"
					width="18"
					height="18"
					viewBox="0 0 24 24"
					fill="none"
					stroke="currentColor"
					stroke-width="2"
					stroke-linecap="round"
					stroke-linejoin="round"
					class="lucide lucide-log-out"
				>
					<path d="M9 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h4" />
					<polyline points="16 17 21 12 16 7" />
					<line x1="21" x2="9" y1="12" y2="12" />
				</svg>

				<span>Logout {{ hostessData?.user?.name }}</span>
			</button>
		}

		<div class="main-content">
			@if (!isHostessLogin) {
				<img src="/assets/admin/images/logo.png" class="content-header-img" alt="" />

				<div class="content-data-login">
					<h3>Authentication</h3>

					<form [formGroup]="form" (ngSubmit)="onFooterBtnClick()" class="input-wrapper">
						<div class="input-group-login">
							<div class="input-group">
								<span class="input-group-text bg-white border-0 input-span-1">
									<img class="input-icon" src="/assets/las/images/person.svg" alt="" />
								</span>
								<input formControlName="identifier" type="email" id="loginInputId" class="form-control border-0" placeholder="Entrer ton login" autocomplete="off" />
								<span class="input-group-text bg-white border-0 input-span-2"></span>
							</div>
						</div>

						<div class="input-group-password">
							<div class="input-group">
								<span class="input-group-text bg-white border-0 input-span-1">
									<img class="input-icon" src="/assets/las/images/key.svg" alt="" />
								</span>
								<input formControlName="password" type="password" id="passwordInputId" class="form-control border-0" placeholder="Entrer ton password" autocomplete="off" />
								<span class="input-group-text bg-white border-0 input-span-2"></span>
							</div>

							<span class="input-warning-text" [class.opacity]="inputWarningMessage !== ''">
								<img class="input-icon-warning" src="/assets/las/images/exclamation.svg" alt="" />
								<span>{{ inputWarningMessage }}</span>
							</span>
						</div>

						<button type="submit" class="btn-hostess">Se Connecter</button>
					</form>
				</div>
			} @else if (isHostessLogin) {
				<div class="campaign-grid">
					@for (campaign of hostessCampaigns; track campaign.id) {
						<div class="campaign-item">
							<div class="campaign-card">
								<div class="campaign-card-image-container">
									<img [src]="campaign?.banner?.url ? backendURL + campaign?.banner?.url : '/assets/admin/images/placeholder.png'" class="campaign-card-image" />
								</div>

								<div class="campaign-card-content">
									<h2 class="campaign-card-title">{{ campaign?.name }}</h2>

									<div class="campaign-card-button">
										<button (click)="isStandalone ? setStartUrl('/las/' + campaign?.slug) : setStartUrlWeb('/las/' + campaign?.slug)">Enter</button>
									</div>
								</div>
							</div>
						</div>
					}
				</div>
			}
		</div>
	</section>
</main>

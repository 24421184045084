import { Injectable } from "@angular/core";
import { Router, UrlTree } from "@angular/router";

@Injectable({
	providedIn: "root",
})
export class WheelGuard {
	constructor(private router: Router) {}

	canActivate(): boolean | UrlTree {
		const routeOrder = JSON.parse(localStorage.getItem("routeOrder") || "[]");
		const slug = JSON.parse(localStorage.getItem("slug"));
		const hasWon = JSON.parse(localStorage.getItem("hasWon"));

		const hostessInfo = localStorage.getItem("hostessInfo");

		if (routeOrder.length > 0 && hasWon) {
			// Check first if the user is hostess and is not in PWA
			if (hostessInfo && !this.checkStandaloneMode()) {
				window.location.href = "/las/hostess";
				return false; // Prevent further navigation in Angular router
			}

			// Redirect to the first route in the order
			localStorage.setItem("hasWon", "false");
			return this.router.createUrlTree([`/las/${slug}/${routeOrder[0]}`]);
		}

		// Allow access to wheel if no routes in order
		return true;
	}

	private checkStandaloneMode(): any {
		if (window.matchMedia("(display-mode: standalone)").matches || (window.navigator as any).standalone) {
			return true;
		} else {
			return false;
		}
	}
}

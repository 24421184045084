import { Component, ElementRef, inject, OnInit, ViewChild, ViewEncapsulation } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { Subject, takeUntil } from "rxjs";
import { LasService } from "../core/services/las.service";
import { environment } from "src/environments/environment";

import { DeviceUUID } from "device-uuid";
import { RestApiService } from "src/app/core/services/rest-api.service";
import { RoutingOrderService } from "src/app/core/services/routing-order.service";
import { ThemeService } from "../core/services/theme.service";
import { RouteOrderGuard } from "../core/guards/route.guard";
import { RoutePositionService } from "../core/services/route.position.service";
import { JourneyOfflineService } from "../core/services/journey.offline.service";

@Component({
	selector: "app-content",
	templateUrl: "./content.component.html",
	styleUrl: "./content.component.scss",
	encapsulation: ViewEncapsulation.None,
})
export class ContentComponent implements OnInit {
	@ViewChild("videoPlayer") videoPlayer: ElementRef<HTMLVideoElement>;
	videoLoaded = false;

	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;
	ngStyle: any;
	isOnline = true;
	isStandalone = false;
	footerBtnText: string;
	campaignHeader: any;
	footerText: any;

	uuid: string = "";

	// LAS Log
	lasData: any;
	stepName: string;
	contentLogVerfication: any;

	// Content
	contentHeaderOtp: boolean;
	contentFooterOtp: boolean;

	contentRouteID: any;
	contentData: any;
	contentDataType: string;

	contentTextdata: any;
	contentImgdata: any;
	contentVideodata: any;

	backgroundImageUrl: string;

	// Final Step
	isFinalStep: boolean = false;

	// Routing config
	isAnyNextRoute: any;

	// Campaign CTA Text
	ctaNextText: string;
	ctaRetryText: string;

	contentCTAOpt: boolean;

	constructor(
		private router: Router,
		private activatedRoute: ActivatedRoute,
		private lasService: LasService,
		private restApiService: RestApiService,
		private routingOrderService: RoutingOrderService,
		private themeService: ThemeService,
		private routeGuard: RouteOrderGuard,
		private routePositionService: RoutePositionService,
		private journeyOfflineService: JourneyOfflineService
	) {}

	// --------------------------------------------------------------------------------------------
	// @ Lifecycle hooks
	// --------------------------------------------------------------------------------------------
	ngOnInit(): void {
		// Get the ID from the activated route and mark it as visited route within the id
		this.activatedRoute.paramMap.subscribe((params) => {
			this.contentRouteID = params.get("id") || "";

			if (this.contentRouteID) {
				this.routeGuard.markRouteAsVisited(`content/${this.contentRouteID}`);
			} else {
				this.routeGuard.markRouteAsVisited("content");
			}

			// Check if this route is the final step
			this.isFinalStep = this.routePositionService.getRouteOrderPosition(this.contentRouteID ? `content/${this.contentRouteID}` : "content");

			// Add step to the journey offline
			this.journeyOfflineService.addStep(this.contentRouteID ? `content/${this.contentRouteID}` : "content", this.isFinalStep);

			this.uuid = new DeviceUUID().get();
			this.initializeLogVerfication();
			this.videoLoaded = false;

			this.lasService.campaignSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data: any) => {
				if (data != null) {
					this.campaignHeader = data.campaign.theme?.header;
					this.footerText = data.campaign.theme?.footer;

					this.ctaNextText = data.campaign.theme.cta?.ctaNextText;
					this.ctaRetryText = data.campaign.theme.cta?.ctaRetryText;

					this.contentData = data.campaign.steps.filter((e) => e.__component === "screen.content")[this.contentRouteID ? this.contentRouteID - 1 : 0];
					this.contentHeaderOtp = this.contentData?.isHeaderEnabled;
					this.contentFooterOtp = this.contentData?.isFooterEnabled;
					this.contentCTAOpt = this.contentData.isCTAEnabled;
					console.log(this.contentCTAOpt);

					this.contentDataType = this.contentData?.type;

					this.contentTextdata = this.contentData?.richText;
					this.contentImgdata = this.backendURL + this.contentData.img?.url;
					this.contentVideodata = this.backendURL + this.contentData.video?.url;

					// Log step
					const stepLebelFormated = this.removeSpacesBetweenWords(this.contentData.stepLabel);
					if (!this.contentLogVerfication[stepLebelFormated]) {
						this.stepName = this.contentData?.stepLabel;
						this.lasService.lasSubject.pipe(takeUntil(this.unsubscriber)).subscribe((data) => {
							if (data != null) {
								this.lasData = data;
								this.logStep(this.lasData.journey, this.stepName, { ...(this.isFinalStep && { isFinalStep: true }) });
							}
						});

						this.updateLogVerfication(this.removeSpacesBetweenWords(this.stepName), "true");
					}

					// Set CTA text
					this.isAnyNextRoute = this.routingOrderService.getNextRoute(this.contentRouteID ? `content/${this.contentRouteID}` : "content");
					this.footerBtnText = this.contentData?.ctaText;
					// if (!this.isAnyNextRoute) {
					// 	this.footerBtnText = this.ctaRetryText;
					// } else {
					// 	if (this.contentData?.ctaText) {
					// 		this.footerBtnText = this.contentData?.ctaText;
					// 	} else {
					// 		this.footerBtnText = this.ctaNextText;
					// 	}
					// }

					// Set theme --------------------
					const theme = data.campaign?.theme;
					const { ngStyle, backgroundImageUrl } = this.themeService.getThemeStyles(theme, this.contentData);
					this.ngStyle = ngStyle;
					this.backgroundImageUrl = backgroundImageUrl;
				}
			});
		});
	}

	ngAfterViewChecked() {
		this.playVideoIfNeeded();
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}

	// --------------------------------------------------------------------------------------------
	// @ Events
	// --------------------------------------------------------------------------------------------
	onFooterBtnClick() {
		// If is final step remove the log verfication
		if (this.isFinalStep) {
			localStorage.removeItem("logVerfication");
			localStorage.removeItem("visitedRoutes");
		}

		// Get the currentPath and check if is a dynamic route or regular route
		const currentPath = this.activatedRoute.snapshot.routeConfig.path;
		const actualPath = currentPath.includes(":id") ? currentPath.replace(":id", this.contentRouteID) : currentPath;

		this.routingOrderService.navigateToNext(actualPath);
	}

	onVideoLoaded() {
		const videoElement = document.querySelector(".video-content video") as HTMLElement;
		if (videoElement) {
			videoElement.style.border = "1px solid var(--campaign-color)";
		}
	}

	// --------------------------------------------------------------------------------------------
	// @ Function
	// --------------------------------------------------------------------------------------------
	logStep(journeyId: number, stepLabel: string, additionalData = {}) {
		this.lasService
			.logStep(journeyId, stepLabel, additionalData)
			.pipe(takeUntil(this.unsubscriber))
			.subscribe({
				next: (response) => {
					console.log("Journey content created:", response);
				},
				error: (error) => {
					console.error("Error creating journey:", error);
				},
			});
	}

	// Initialize LogVerfication & Update the component log
	private initializeLogVerfication() {
		const storedState = localStorage.getItem("logVerfication");
		this.contentLogVerfication = storedState ? JSON.parse(storedState) : {};
	}

	private updateLogVerfication(key: string, value: any) {
		this.contentLogVerfication[key] = value;
		localStorage.setItem("logVerfication", JSON.stringify(this.contentLogVerfication));
	}

	// Remove spaces from the text
	removeSpacesBetweenWords(text: any) {
		return text.replace(/(\S)\s+(\S)/g, "$1$2");
	}

	private playVideoIfNeeded() {
		if (this.contentData?.type === "video" && this.videoPlayer && !this.videoLoaded) {
			const video = this.videoPlayer.nativeElement;
			video.muted = true;

			const playPromise = video.play();

			if (playPromise !== undefined) {
				playPromise
					.then((_) => {
						// Automatic playback started!
						this.videoLoaded = true;
					})
					.catch((error) => {
						// Auto-play was prevented
						console.error("Error attempting to play video:", error);
					});
			}
		}
	}
}

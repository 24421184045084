import { Component, OnInit, ViewEncapsulation } from "@angular/core";
import { Subject } from "rxjs";
import { AuthService } from "src/app/core/auth/auth.service";
import { environment } from "src/environments/environment";

@Component({
	selector: "app-admin",
	templateUrl: "./admin.component.html",
	styleUrls: [
		"../../../../node_modules/prismjs/themes/prism.css",
		"../../../../node_modules/shepherd.js/dist/css/shepherd.css",
		"../../../../node_modules/flatpickr/dist/flatpickr.css",
		"../../../../src/assets/admin/scss/styles.scss",
		"../../../../node_modules/@ctrl/ngx-emoji-mart/picker.css",
		"../../../../node_modules/slick-carousel/slick/slick.scss",
		"../../../../node_modules/slick-carousel/slick/slick-theme.scss",
		"../../../../src/assets/admin/scss/config/material/bootstrap.scss",
		"../../../../src/assets/admin/scss/config/material/app.scss",
		"../../../../src/assets/admin/scss/icons.scss",
		"../../../../node_modules/ngx-toastr/toastr.css",
	],
	encapsulation: ViewEncapsulation.None,
})
export class AdminComponent implements OnInit {
	unsubscriber: Subject<any> = new Subject<any>();
	backendURL = environment.backendURL;

	constructor(private authService: AuthService) {}

	ngOnInit() {
		this.authService.userSubject.next(this.authService.user);
	}

	ngOnDestroy(): void {
		this.unsubscriber.next(null);
		this.unsubscriber.complete();
	}
}
